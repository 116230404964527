<template>
  <div
    class="header__language"
    style="user-select: none"
    @click="toggleLanguage"
  >
    {{ $i18n.locale }}
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { getAllCities } from "@/api"
import { actionTypes } from "@/store"
import { actionTypes as actionTypesCity } from "@/store/modules/city"

export default {
  name: "LangChange",
  computed: {
    ...mapState(["currentLanguage"]),
    ...mapState("catalogModule", { selectedFilters: "filters" }),
    ...mapState("cityModule", ["selectedCity"]),
  },
  methods: {
    ...mapActions({ changeLang: actionTypes.changeCurrentLanguage }),
    ...mapActions("cityModule", {
      getAllCities: actionTypesCity.loadAllCities,
      saveChoosedCity: actionTypesCity.changeSelectedCity,
    }),
    toggleLanguage() {
      if (this.$i18n.locale === "ru") {
        this.changeLang("kz")
        this.$i18n.locale = "kz"
        getAllCities().then((cities) => {
          const qsCity = this.$route.query.city_id
            ? parseInt(this.$route.query.city_id)
            : JSON.parse(localStorage.getItem("selectCity").id) || 2
          const city = cities.find((c) => c.id === qsCity)
          this.$router.onReady(() => {
            if (!this.$route.query.city_id) {
              this.$router.push({
                params: { lang: this.$i18n.locale || "ru" },
                query: { city_id: city?.id || city || 2 },
              })
            }
          })
          this.saveChoosedCity(city)
        })
        localStorage.setItem("lang", this.$i18n.locale)
        this.$router.push({
          params: { lang: this.$i18n.locale },
          query: this.$route.query,
        })

        return
      }
      this.changeLang("ru")
      this.$i18n.locale = "ru"
      getAllCities().then((cities) => {
        const qsCity = this.$route.query.city_id
          ? parseInt(this.$route.query.city_id)
          : JSON.parse(localStorage.getItem("selectedCity")) || 2
        const city = cities.find((c) => c.id === qsCity)
        this.$router.onReady(() => {
          if (!this.$route.query.city_id) {
            this.$router.push({
              params: { lang: this.$i18n.locale || "ru" },
              query: { city_id: city?.id || city || 2 },
            })
          }
        })
        this.saveChoosedCity(city)
      })
      localStorage.setItem("lang", this.$i18n.locale)
      this.$router.push({
        params: { lang: this.$i18n.locale },
        query: this.$route.query,
      })
    },
  },
}
</script>
